// Risk analysis
export const flowIdRiskAnalysisNL = '4fccc81c-c38c-4a1f-abb3-c141edc77a2f';
export const flowIdRiskAnalysisBE = '8b651328-5304-4980-bb23-177d6694a34b';
export const flowIdRiskAnalysisShortBE = '61c125a5-6475-4f6f-a901-f2ae336b1dca';

export const riskAnalysisFlowIds = [
  flowIdRiskAnalysisNL,
  flowIdRiskAnalysisBE,
  flowIdRiskAnalysisShortBE,
];

// Sales conversation
export const flowIdSalesConversation = '97f9d89e-2c28-49e8-a6f2-0f25e89b42c8';
export const flowIdDeMotoVerzekering = '74fee5e3-385d-463e-9f64-35b3892438f8';
export const flowIdVillasureOfferFlow = '2e65ee48-d1b2-4ed6-a6f4-dddf74cb0b33';
export const flowIdVillasureContractFlow =
  'aea262bc-d47c-46db-9057-58662f9636e0';
export const flowIdPiaBoatOfferFlow = '752df63f-2399-4a19-989e-0a2b478b68ab';
export const flowIdPiaBoatContractFlow = '8d5bf1d3-1b8d-4026-aaec-317a841dbd61';
export const flowIdBikmoOfferFlow = 'df934285-3fcb-4701-8c6f-bdf794a1806b';
export const flowIdBikmoContractFlow = '3a956a89-8570-4974-ab87-bbb4292f1674';
export const flowIdCyclingVlaanderenOfferFlowId =
  'ae2f00d5-8bc0-429e-a7bf-d6d7827fd2d4';
export const flowIdCyclingVlaanderenContractFlowId =
  '11b80d10-cfd1-439d-b8d3-a6108a3b8019';

// 2024

// Checkup flows
/** NL */
export const flowIdShortCheckupCompanyNL =
  '4030918c-df45-4920-adf5-d3217284bb1e';
export const flowIdShortCheckupCustomerNL =
  '3e4ab13f-752c-43c5-8ff4-2946fb998785';
/** BE */
export const flowIdShortCheckupCompanyBE =
  '37fc19f6-6c09-4d27-aca6-0378f4f97489';
export const flowIdShortCheckupCustomerBE =
  '21f46cd2-3e65-4d7a-93d5-99bcee38c840';

export const companyCheckupFlowIds = [
  flowIdShortCheckupCompanyNL,
  flowIdShortCheckupCompanyBE,
];

export const customerCheckupFlowIds = [
  flowIdShortCheckupCustomerNL,
  flowIdShortCheckupCustomerBE,
];

export const flowIdCfVerzekeringen = '178584dd-0993-4586-bf44-5e294fed9ab2';

// Aska
export const flowIdAskaTravelOffer = 'f02aef70-d126-4569-95a8-b55cdf23876f';
export const flowIdAskaTravelContract = '371e2856-bab9-4d58-9286-70497b965f71';

// Company scan
export const flowIdCompanyScanBE = '83882ca1-af11-4776-a92a-793ebf149d42';
export const flowIdCompanyScanNL = 'f2487edd-d796-4f8f-9a70-c61c96b8e01d';
export const flowIdGoudseCompanyScan = '1002c5d6-8a9a-41ad-8ed1-d897ada7183b';
export const companyScanFlowIds = [
  flowIdCompanyScanBE,
  flowIdCompanyScanNL,
  flowIdGoudseCompanyScan,
];

export const flowIdTechniekNL = '189d302d-4e79-4b13-871a-4b6220553666';

// Citizins
export const flowIdCitizinsTravel = '7f11cf73-2d93-4f60-9ac4-6964f46b57fd';
export const flowIdCitizinsTravelContract =
  '4bf6c033-ff27-4610-b349-97cbc2005b57';

export const flowIdCitizinsCarAruba = '12ef3fa6-9d6e-49c1-ac87-58c1fb18de4c';
export const flowIdCitizinsCarArubaContract =
  '58fb74d7-a161-41db-ad8d-c0883ae81f00';

export const flowIdCitizinsCarCuracao = 'a22348b8-c3d2-42ac-a3cc-56f2c5bc656f';
export const flowIdCitizinsCarCuracaoContract =
  '3f18f9d7-3d37-4575-ba6c-849411e3f9b2';

export const flowIdCitizinsMedicalAruba =
  '212131b5-c5b0-4319-af3b-008c3c96b1d3';

export const flowIdCitizinsMedicalCuracao =
  'f6c62f63-8856-4521-84b0-8ef4968684e0';
