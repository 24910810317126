import { createDispatchHook, createSelectorHook } from 'react-redux';
import { RootState as GlobalRootState } from '../store/redux/reducers/rootReducer';
import { RootState as FlowRootState } from '../../../flows/src/store/redux/reducers/rootReducer';
import GlobalContext from '../store/context/GlobalReduxContext';
import FlowsContext from '../../../flows/src/store/context/flowsReduxContext';
import {
  flowIdAskaTravelContract,
  flowIdAskaTravelOffer,
  flowIdCfVerzekeringen,
  flowIdCitizinsCarAruba,
  flowIdCitizinsCarArubaContract,
  flowIdCitizinsCarCuracao,
  flowIdCitizinsCarCuracaoContract,
  flowIdCitizinsMedicalAruba,
  flowIdCitizinsMedicalCuracao,
  flowIdCitizinsTravel,
  flowIdCitizinsTravelContract,
  flowIdRiskAnalysisBE,
  flowIdRiskAnalysisNL,
  flowIdRiskAnalysisShortBE,
  flowIdSalesConversation,
  flowIdShortCheckupCompanyBE,
  flowIdShortCheckupCompanyNL,
  flowIdShortCheckupCustomerBE,
  flowIdShortCheckupCustomerNL,
} from '../../../flows/src/constants/flowIds';
import { RootState as CompanyScanRootState } from '../../../company-scan/store/redux/reducers/rootReducer';
import { RootState as CampaignsRootState } from '../../../campaigns/src/store/redux/reducers/rootReducer';
import CompanyScanContext from '../../../company-scan/store/context/CompanyScanContext';
import CampaignsContext from '../../../campaigns/src/store/context/CampaignsContext';

export const isRiskAnalysisFlow = (): boolean =>
  (window.location.pathname.includes('/flows') &&
    (window.location.pathname.includes(flowIdRiskAnalysisBE) ||
      window.location.pathname.includes(flowIdRiskAnalysisShortBE) ||
      window.location.pathname.includes(flowIdRiskAnalysisNL))) ||
  window.location.pathname.includes('/risk-analysis');

export const isCompanyCheckupFlow = (): boolean =>
  window.location.pathname.includes('/flows') &&
  (window.location.pathname.includes(flowIdShortCheckupCompanyNL) ||
    window.location.pathname.includes(flowIdShortCheckupCompanyBE));

export const isCustomerCheckupFlow = (): boolean =>
  window.location.pathname.includes('/flows') &&
  (window.location.pathname.includes(flowIdShortCheckupCustomerNL) ||
    window.location.pathname.includes(flowIdShortCheckupCustomerBE));

export const isAskaFlow = (): boolean =>
  window.location.pathname.includes('/flows') &&
  (window.location.pathname.includes(flowIdAskaTravelOffer) ||
    window.location.pathname.includes(flowIdAskaTravelContract));

export const isCitizinsFlow = (): boolean =>
  window.location.pathname.includes('/flows') &&
  (window.location.pathname.includes(flowIdCitizinsTravel) ||
    window.location.pathname.includes(flowIdCitizinsTravelContract) ||
    window.location.pathname.includes(flowIdCitizinsCarAruba) ||
    window.location.pathname.includes(flowIdCitizinsCarArubaContract) ||
    window.location.pathname.includes(flowIdCitizinsCarCuracao) ||
    window.location.pathname.includes(flowIdCitizinsCarCuracaoContract) ||
    window.location.pathname.includes(flowIdCitizinsMedicalAruba) ||
    window.location.pathname.includes(flowIdCitizinsMedicalCuracao));

export const isSalesConversationFlow = (): boolean =>
  window.location.pathname.includes('/flows') &&
  window.location.pathname.includes(flowIdSalesConversation);

export const isCfVerzekeringenFlow = (): boolean =>
  window.location.pathname.includes('/flows') &&
  window.location.pathname.includes(flowIdCfVerzekeringen);

export const isStaging =
  process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT' ||
  process.env.REACT_APP_ENVIRONMENT === 'STAGING' ||
  process.env.REACT_APP_ENVIRONMENT === 'QA';

export const useGlobalSelector =
  createSelectorHook<GlobalRootState>(GlobalContext);
export const useGlobalDispatch = createDispatchHook(GlobalContext);

export const useFlowsSelector = createSelectorHook<FlowRootState>(FlowsContext);
export const useFlowsDispatch = createDispatchHook(FlowsContext);

export const useCompanyScanSelector =
  createSelectorHook<CompanyScanRootState>(CompanyScanContext);
export const useCompanyScanDispatch = createDispatchHook(CompanyScanContext);

export const useCampaignsSelector =
  createSelectorHook<CampaignsRootState>(CampaignsContext);
export const useCampaignsDispatch = createDispatchHook(CampaignsContext);
